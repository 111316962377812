body {
  margin: 0;
}

#hide {
  position: fixed;
  top: 50vh;
  left: 40vh;
}

#height {
  position: fixed;
  top: 5vh;
  left: 5vh;
}

.branchsubmenu .MuiPopover-paper {
  /* clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 75%, 0 70%); */
  left: 0px !important;
  max-width: calc(100%);
  width: 100%
}

.mobilebranchsubmenu .MuiPopover-paper {
  /* clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 75%, 0 70%); */
  left: 0px !important;
  max-width: calc(100%);
  width: 100%
}

.submenu .MuiPopover-paper {
  /* clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 90%, 0 80%); */
  clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 75%, 0 70%);
  left: 0px !important;
  max-width: calc(100%);
  width: 100%
}

@media (max-width: 600px) {

  /* Adjust the breakpoint as needed for xs screens */
  .submenu .MuiPopover-paper {
    clip-path: none;
    /* Remove clip-path for xs screens */
  }
}

@media (max-width: 600px) {

  /* Adjust the breakpoint as needed for xs screens */
  .branchsubmenu .MuiPopover-paper {
    clip-path: none;
    /* Remove clip-path for xs screens */
  }


  .mobilebranchsubmenu .MuiPopover-paper {
    clip-path: none;
    /* Remove clip-path for xs screens */
  }


}

.search .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  display: none;
}

.search {
  width: 50%;
  padding: 0px !important;
  border-radius: 10px;
  background: #e1f3ff;
}

.search .MuiOutlinedInput-root {
  padding: 0px !important;
  border-radius: 10px;
  background: #e1f3ff;
}

.location-icon:hover {
  color: "#3C14E2";
  text-decoration: underline;
}

.location-icon {
  transition: color 0.3s;
  /* Add transition for smooth color change */
}

@media screen and (min-width: 1440px) {
  .headerpopover {
    border: 1px solid red !important;
    width: 200px;
  }
}