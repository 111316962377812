.healthportalmain {
    padding: 20px 35px;
}

.carereminder {
    padding: 5px;
}

.carefont {
    font-size: 14px;
    color: #9560D9;
    font-weight: 500;
}

.fntsz-18 {
    font-size: 18px !important;
}

.fntsz-15 {
    font-size: 15px !important;
}

.fntsz-14 {
    font-size: 14px !important;
}

.fntsz-13 {
    font-size: 13px !important;
}

.fntsz-12 {
    font-size: 12px !important;
}

.fntwt-500 {
    font-weight: 500 !important;
}

.fntwt-600 {
    font-weight: 600 !important;
}

.textcol {
    color: #155775;
}

.textbgcol {
    background-color: #155775;
}

.mt-05 {
    margin-top: 5px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.txtAlign-c {
    text-align: center !important;
}

.txtAlign-l {
    text-align: left;
}

.txtAlign-r {
    text-align: right;
}