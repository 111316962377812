.doctorBg {
    background-image: url(../../assets/doctor/doctorcover.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 250px;
    margin-top: 0px;
}

.healthServiceBg {
    background-image: url(../../assets/doctor/hscoverpic.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* height: 150px; */
    height: 250px;
    margin-top: 0px;
}

.healthDeptBg {
    background-image: url(../../assets/doctor/hdcoverpic.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* height: 150px; */
    height: 250px;
    margin-top: 0px;
}

.doctorprofiletab {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.doctorprofiletab::-webkit-scrollbar {
    display: none;
}

.add-option {
    padding: 2px 20px !important;
    margin-top: 10px !important;
    /* border:1px solid #106DBE!important; */
    border-radius: 15px !important;
    font-size: 12px;
    text-transform: capitalize !important;
}

@media screen and (max-width: 376px) {
    #line_height {
        line-height: 20px;
    }

    #share_social {
        line-height: 12px;
        font-size: 10px;
    }

    .inside_icon {
        width: 15px !important;
        height: 15px !important;
    }
}

.inside_icon {
    width: 20px !important;
    height: 20px !important;
}