.EmojiPickerReact .epr-preview {
    display: none;
}

.EmojiPickerReact .epr-category-nav {
    padding-top: 5px;
    padding-bottom: 5px;
}

.EmojiPickerReact .epr-header .epr-header-overlay {
    padding-top: 5px;
    padding-bottom: 5px;
}

/* .textarea {  
    overflow: hidden;
    margin:  0;
    padding: 0;
    border:  0;
    outline: 0;
    width: 100%;
    font-size: 14px;
    resize: none;
    position: relative;
  } */

.backArrow {
    display: flex;
    align-self: center;
    padding-right: 20px;
}

.h-\[27rem\] {
    height: 28rem;
}

.max-w-xl {
    max-width: 36rem;
}

.dateStampBox {
    background-color: gray;
    color: #fff;
    padding: 2px 10px;
    font-size: 10px;
    border-radius: 6px;
}

.message-box {
    position: fixed;
    bottom: 0.38rem;
    right: 0.5rem;
    z-index: 9999 !important;
}

.whatsappButton {
    width: 60px !important;
    height: 60px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* position: relative !important; */
    /* bottom: 2rem;
    right: 2rem; */
    cursor: pointer;
    background-color: #25d366 !important;
    border-radius: 50%;
    user-select: none;
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 15%);
    z-index: 9998;
}

.whatsappButton:after {
    content: "";
    border-color: inherit;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    position: absolute;
    box-shadow: 0 0 0 0 rgba(37, 211, 101, 0);
    animation: _pulse 1.2s infinite;
}



.floating-whatsapp-button {
    position: static !important;
}