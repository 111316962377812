.conditionBg {
    background-image: url(../../assets/doctor/conditioncover.png);
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: cover;
    height: 150px;
}

.cndtab {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.cndtab::-webkit-scrollbar {
    display: none;
}