.carousalhasNextBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 90px;
    right: 0;
    background-color: white;
    z-index: 2;
}

.carousalprevsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 90px;
    left: 0;
    background-color: white;
    z-index: 2;
}